import React from 'react'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Legal from '../components/Legal'

const MentionsLegalesPageTemplate = (props) => {
  return <Legal {...props} />
}

const MentionsLegalesPage = ({ path }) => {
  return (
    <Layout>
      <Helmet>
        <title>Règles et Mentions légales de la plateforme</title>
        <meta name="description" content="Règles et mentions légales de la plateforme d’intermédiation de freelance ingénieurs et grands comptes de l’industrie et du bâtiment" />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Règles et Mentions légales de la plateforme" />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Règles et mentions légales de la plateforme d’intermédiation de freelance ingénieurs et grands comptes de l’industrie et du bâtiment" />
        <link rel="canonical" href={`${process.env.GATSBY_WEBSITE_URL}/mentions-legales`} />
      </Helmet>

      <MentionsLegalesPageTemplate activeTab={path.substr(1)} />
    </Layout>
  )
}

export default MentionsLegalesPage
