import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useStaticQuery, graphql } from 'gatsby'

import ContentWrapper from '../ContentWrapper'
import AppTabs from '../AppTabs'

import * as s from './style.module.sass'

const Legal = ({ activeTab }) => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { templateKey: { eq: "mentions-legales" } }) {
        frontmatter {
          title
          introText
          cgu
          mentions
          rgpd
        }
      }
    }
  `)
  const { title, introText, cgu, mentions, rgpd  } = data.markdownRemark.frontmatter

  return (
    <div className={s.section}>
      <ContentWrapper>
        <div className={s.heading}>
          <h1>{title}</h1>
          <p>{introText}</p>
        </div>
        <div className={s.legalContainer}>
          <AppTabs tabs={[
            {
              title: 'Conditions générales',
              Component: ReactMarkdown,
              componentProps: {
                children: cgu
              },
              active: activeTab === 'conditions-generales'
            },
            {
              title: 'Mentions légales',
              Component: ReactMarkdown,
              componentProps: {
                children: mentions
              },
              active: activeTab === 'mentions-legales'
            },
            {
              title: 'Données personnelles',
              Component: ReactMarkdown,
              componentProps: {
                children: rgpd
              },
              active: activeTab === 'rgpd'
            }]}
          />
        </div>
      </ContentWrapper>
    </div>
  )
}

export default Legal
