/* eslint jsx-a11y/click-events-have-key-events: 0 jsx-a11y/no-static-element-interactions: 0 */
import React, { useState, useEffect } from 'react'
import * as s from './style.module.sass'

const AppTabs = ({ tabs }) => {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const { Component, componentProps } = currentTab

  useEffect(() => {
    setCurrentTab(tabs.find(t => t.active) || tabs[0])
  }, [tabs])

  return (
    <div className={s.container}>
      <div className={s.tabs}>
        {tabs.map(t => (
          <div
            key={t.title}
            className={[s.tabTitle, currentTab === t ? s.active : ''].join(' ')}
            onClick={() => setCurrentTab(t)}
          >
            <div className={s.titleText}>{t.title}</div>
            <div className={s.titleBorder} />
          </div>
        ))}
      </div>
      <div className={s.tabsContent}>
        <Component {...componentProps} />
      </div>
    </div>
  )
}

export default AppTabs
